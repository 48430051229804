import { authStore } from '@/stores/auth'
import { notification } from 'ant-design-vue'

export function errorNotification (title: string, message: string, duration = 0) : void {
  notification.error({
    message: title,
    description: message + ' (' + getErrorCode() + ')',
    duration: duration,
    placement: 'bottomRight'
  })
}

export function successNotification (title: string, message: string, duration = 4) : void {
  notification.success({
    message: title,
    description: message,
    duration: duration,
    placement: 'bottomRight'
  })
}

function getErrorCode () : string {
  const auth = authStore()
  const now = new Date()

  if (auth.user && auth.user.id) {
    return ('0' + now.getHours()).slice(-2) + ('0' + now.getMinutes()).slice(-2) + '-' + auth.user.id
  } else {
    return ('0' + now.getHours()).slice(-2) + ('0' + now.getMinutes()).slice(-2) + '-X'
  }
}
